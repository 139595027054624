<template>
  <b-card>
    <b-col class="form-container">
      <h4 class="mb-2">Zgłoszenie współpracy</h4>

      <b-form-group label="Zgłaszający">
        <b-form-input v-model="cooperationRecord.fullname" disabled />
      </b-form-group>

      <b-form-group label="Email">
        <b-form-input v-model="cooperationRecord.email" disabled />
      </b-form-group>

      <b-form-group label="Telefon">
        <b-form-input v-model="cooperationRecord.phone" disabled />
      </b-form-group>

      <b-form-group label="Temat">
        <b-form-input v-model="cooperationRecord.topic" disabled />
      </b-form-group>

      <b-form-group label="Data zgłoszenia">
        <b-form-input v-model="cooperationRecord.created_at" disabled />
      </b-form-group>

      <b-form-group label="Wiadomość">
        <b-form-textarea v-model="cooperationRecord.content" rows="8" disabled />
      </b-form-group>
    </b-col>
  </b-card>
</template>

<script>
import { show } from '@/http/cooperation';

export default {
  data() {
    return {
      cooperationRecord: {
        fullname: '',
        email: '',
        phone: '',
        topic: '',
        content: '',
        created_at: '',
      },
    };
  },

  created() {
    this.fetchCooperationRecord();
  },

  methods: {
    async fetchCooperationRecord() {
      try {
        const { data } = await show(this.$route.params.id);

        this.cooperationRecord = data.data;
      } catch (error) {
        this.$toast.error({
          title: 'Błąd',
          message: 'Nie udało się pobrać danych',
        });
      }
    },
  },
};
</script>
