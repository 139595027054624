import axios from '../axios';

export const all = () => {
  return axios.get('/cooperation');
};

export const show = (id) => {
  return axios.get(`/cooperation/${id}`);
};

export const destroy = (id) => {
  return axios.delete(`/cooperation/${id}`);
};
